import React from "react"
import { graphql, Link } from "gatsby"
import { DiscussionEmbed } from "disqus-react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const renderTags = tags => (
  <ul className="tag-container">
    {tags.map(tag => (
      <li key={tag} className="tag mini">
        <Link to={`/tags/${tag}`}>{tag}</Link>
      </li>
    ))}
  </ul>
)

const renderListItems = items =>
  items.map((item, i) => {
    const { name, description, url, additionalLinks } = item
    return (
      <li key={`list-item-${i}`}>
        <h4 className="item-name">
          <a href={url} target="_blank" rel="noopener noreferrer">
            {name}
          </a>
        </h4>
        {description && <p className="item-desc">{description}</p>}
        {additionalLinks && (
          <div className="item-info wordBreak">
            {renderListAdditionalLinks(additionalLinks)}
          </div>
        )}
      </li>
    )
  })

const renderListAdditionalLinks = links =>
  links.map((link, i) => {
    const { label, url } = link
    return (
      <p key={`list-additional-links-${i}`}>
        {label}:{" "}
        <a href={url} target="_blank" rel="noopener noreferrer">
          {url}
        </a>
      </p>
    )
  })

export default ({ data }) => {
  const { id, name, description, tags, items } = data.listJson
  const disqusConfig = {
    shortname: process.env.GATSBY_DISQUS_NAME,
    config: { identifier: id, title: name },
  }

  return (
    <Layout>
      <SEO title={name} description={description} />
      <div className="container full no-margin">
        <div className="container textCenter">
          <h2>
            Hand-picked list of <br />'{name}'
          </h2>
          {description && <p>{description}</p>}
        </div>
        {tags && tags.length > 0 && (
          <div className="container no-padding bottom-padding-double">
            {renderTags(tags)}
          </div>
        )}
        <div className="container no-padding">
          <ul className="list">{renderListItems(items)}</ul>
        </div>
        <div className="container no-padding top-padding">
          <DiscussionEmbed {...disqusConfig} />
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query($id: String!) {
    listJson(id: { eq: $id }) {
      id
      name
      description
      tags
      items {
        name
        description
        url
        additionalLinks {
          label
          url
        }
      }
    }
  }
`
